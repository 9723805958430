import React, { useState } from 'react';
import './HeaderIcon.scss';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChronolinePopup from '../ChronolinePopup/ChronolinePopup';

interface PopupProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
type HeaderIconProps = {
  icon: IconDefinition;
  children: React.ReactElement<PopupProps> | React.ReactElement;
};
export default function HeaderIcon({ icon, children }: HeaderIconProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <button className="icon" onClick={() => setIsOpen((o) => !o)}>
        <FontAwesomeIcon icon={icon} />
      </button>
      <ChronolinePopup isOpen={isOpen} setIsOpen={setIsOpen}>
        {children}
      </ChronolinePopup>
    </>
  );
}
