import { dailyIds } from '../../utils/dailyDeck';
import { Card, GameMode } from '../../utils/types';
import { getCurrentDate } from '../../utils/getCurrentDate';
import { worldHistoryCards } from '../../utils/cards';
import { peopleCards } from '../../utils/PeopleCards';
import { inventionCards } from '../../utils/inventionCards';
import getRandomDeck from '../../utils/getRandomDeck';

export function getNextCard(
  cardsToPlay: Card[],
  nextCard: Card[]
): [Card[] | undefined, Card[]] {
  if (cardsToPlay.length > 0 && nextCard) {
    let cardList = [...cardsToPlay];
    const [nextCard] = [cardList.shift()!];
    nextCard.isFlipped = false;
    return [[nextCard], cardList];
  }
  return [undefined, cardsToPlay];
}

export function getTodayHistoric() {
  const currentDateItem = dailyIds[getCurrentDate() as keyof typeof dailyIds];

  let dailyDeck: Card[];
  const allCards = worldHistoryCards.concat(peopleCards).concat(inventionCards);

  if (currentDateItem) {
    dailyDeck = currentDateItem.cardIds.map(
      (cardId) => allCards.find((card) => card.id === cardId)!
    );
  } else {
    dailyDeck = dailyIds['23/02/2023'].cardIds.map(
      (cardId) => allCards.find((card) => card.id === cardId)!
    );
  }
  return dailyDeck;
}

export function getGameModeDeck(gameMode: GameMode, nOfCards: number): Card[] {
  let deckCopy;
  if (gameMode === GameMode.DAILY) {
    deckCopy = [...getTodayHistoric()];
  } else if (gameMode === GameMode.WORLD_HISTORY) {
    deckCopy = getRandomDeck(worldHistoryCards, nOfCards);
  } else if (gameMode === GameMode.INVENTION) {
    deckCopy = getRandomDeck(inventionCards, nOfCards);
  } else {
    deckCopy = getRandomDeck(peopleCards, nOfCards);
  }
  return deckCopy;
}
