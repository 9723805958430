import './Instructions.scss';
import '../Header/Header.scss';
import gif from '../../assets/chronoline_gif_small.gif';
import { useTranslation } from 'react-i18next';

export function Instructions(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <div className="ctn">
        <h1>{t('instructions.title')}</h1>
        <p className="text">{t('instructions.mainText')}</p>
        <p className="text">✅ {t('instructions.correct')}</p>
        <p className="text">❌ {t('instructions.wrong')}</p>
        <div className="gif-ctn">
          <img
            src={gif}
            alt="Dragging a card in the right position, then dragging another card in the wrong one."
          />
        </div>
        <h1>{t('instructions.modesTitle')}</h1>
        <div className="text">
          {t('instructions.modes')}
          <ul className="text">
            <li> 📅 {t('instructions.daily')}</li>
            <li> 👸🏻 {t('instructions.biographical')}</li>
            <li> 🏛️ {t('instructions.historic')}</li>
            <li> 💡 {t('instructions.inventions')}</li>
          </ul>
        </div>
      </div>
    </>
  );
}
