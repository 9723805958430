import './StatisticsPage.scss';

import { useContext } from 'react';
import { GameHistoricContext } from '../../App';
import { useTranslation } from 'react-i18next';
import {
  calcAvgMistakes,
  calcMaxStreak,
  calcMistakeDistrib,
  calcStreak,
  calcWinsPercentage,
  countDaysPlayed,
  normaliseMistakes,
} from './StatisticsPageFns';

export function Stats(): JSX.Element {
  const { gameHistoric } = useContext(GameHistoricContext);
  const { t } = useTranslation();

  const mistakes = [0, 1, 2, 3, 4, 5];

  return (
    <>
      <main className="stats-main-body">
        <h1 className="stats-title">{t('statistics.title')}</h1>
        <div className="stats-ctn">
          <p className="stats-value">{calcWinsPercentage(gameHistoric)}</p>
          <p className="stats-value">{countDaysPlayed(gameHistoric)}</p>
          <p className="stats-value">{calcAvgMistakes(gameHistoric)}</p>
          <p className="stats-value">{calcStreak(gameHistoric)}</p>
          <p className="stats-value">
            {calcMaxStreak(calcStreak(gameHistoric))}
          </p>
          <p className="stats-item">{t('statistics.win')}</p>
          <p className="stats-item">{t('statistics.played')}</p>
          <p className="stats-item">{t('statistics.mistakes')}</p>
          <p className="stats-item">{t('statistics.streak')}</p>
          <p className="stats-item">{t('statistics.maxStreak')}</p>
        </div>
        <h2 className="stats-title">{t('statistics.mistakeDistribution')}</h2>
        <ul>
          {mistakes.map((mistake) => {
            return (
              <div key={mistake} className="chart-ctn">
                <p className="mistake-item">{mistake !== 5 ? mistake : 'X'}</p>
                <li
                  style={{
                    flex: `0 1 ${
                      normaliseMistakes(calcMistakeDistrib(gameHistoric))[
                        mistake
                      ]
                    }%`,
                  }}
                  className="bar"
                >
                  <span className="mistake-count">
                    {calcMistakeDistrib(gameHistoric)[mistake]}
                  </span>
                </li>
              </div>
            );
          })}
        </ul>
      </main>
    </>
  );
}
