import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

interface StartOverProps {
  startOver: () => void;
}

export function StartOver({ startOver }: StartOverProps): JSX.Element {
  return (
    <button className="icon" onClick={startOver}>
      <FontAwesomeIcon icon={faRefresh} />
    </button>
  );
}
