import { dailyIds } from './dailyDeck';

export function getCurrentDate() {
  const currentDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return currentDate;
}

export function getCurrentDateNumber() {
  const currentDateNumber = dailyIds[getCurrentDate() as keyof typeof dailyIds];
  return currentDateNumber?.number;
}
