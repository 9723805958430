import { Card } from './types';

/**
 * getRandomDeck gets x amount of random cards out of the deck
 * @param cards
 */

export default function getRandomDeck(cards: Card[], nOfCards: number): Card[] {
  let shuffled = cards
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled.slice(0, nOfCards);
}
