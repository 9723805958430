import {
  faBarChart,
  faCircleQuestion,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { checkCorrect } from '../../utils/checkCorrect';
import {
  getCurrentDate,
  getCurrentDateNumber,
} from '../../utils/getCurrentDate';
import { useLocalStorage } from '../../hooks/localStorage';
import { Card, GameHistoric, GameMode, GameState } from '../../utils/types';
import { EndGameView } from '../EndGame/EndGameView';
import NextCard from '../NextCard/NextCard';
import PlayedCards from '../PlayedCards/PlayedCards';
import './Board.scss';
import * as boardFns from './BoardFns';
import ScorePanel from '../ScorePanel/ScorePanel';
import { StartOver } from '../EndGame/StartOver';
import { useFirstLogin } from '../../hooks/useFirstLogin';
import { Instructions } from '../Instructions/Instructions';
import ChronolinePopup from '../ChronolinePopup/ChronolinePopup';
import HeaderIcon from '../HeaderIcon/HeaderIcon';
import { Stats } from '../StatisticsPage/StatisticsPage';
import axios from 'axios';
interface BoardProps {
  lifes: number;
  pointsToWin: number;
  gameMode: GameMode;
  setGameHistoric: React.Dispatch<React.SetStateAction<GameHistoric>>;
}

function Board({ lifes, gameMode, pointsToWin, setGameHistoric }: BoardProps) {
  let navigate = useNavigate();

  const isDaily = gameMode === GameMode.DAILY;

  let deckCopy = boardFns.getGameModeDeck(gameMode, pointsToWin + lifes + 2);

  const [initialNextCard] = [deckCopy.shift()!];
  const [initialPlayedCard] = [deckCopy.shift()!];

  const [cardToFlip, setCardToFlip] = useState<Card>(initialPlayedCard);
  const [isDragging, setIsDragging] = useState<boolean>(true);

  const [gameState, setGameState] = useLocalStorage<GameState>(
    `chronoline_${gameMode}_game_state`,
    {
      win: undefined,
      rightCards: [{ ...initialPlayedCard, isFlipped: true }] as Card[],
      wrongCards: [] as Card[],
      nextCard: [initialNextCard] as Card[],
      number: getCurrentDateNumber(),
      cardsToPlay: deckCopy,
    }
  );
  const [progress, setProgress] = useState(gameState.rightCards.length - 1);

  const [isOpen, setIsOpen] = useFirstLogin();

  const updateGameHistoric = useCallback(() => {
    const currentDate = getCurrentDate();
    const rightCardsIds: string[] = gameState.rightCards.map((card) => card.id);
    const wrongCardsIds: string[] = gameState.wrongCards.map((card) => card.id);
    const cardsToPlayIds: string[] = gameState.cardsToPlay.map(
      (card) => {
        if (card){
          return card.id;
        } else {
          const id = Math.floor(Math.random()*86);
          return `p-${id}`;
        }
      } 
    );
    const nextCardId: string | undefined =
      gameState.nextCard && gameState.nextCard[0].id;

    return {
      [currentDate]: {
        rightCardsIds: rightCardsIds,
        wrongCardsIds: wrongCardsIds,
        cardsToPlayIds: cardsToPlayIds,
        number: gameState.number,
        win: gameState.win,
        nextCardId: nextCardId,
      },
    };
  }, [gameState]);

  useEffect(() => {
    const updatedEntry: GameHistoric = updateGameHistoric();
    if (gameMode === GameMode.DAILY)
      setGameHistoric((prevState: GameHistoric): GameHistoric => {
        return { ...prevState, ...updatedEntry };
      });
  }, [setGameHistoric, updateGameHistoric, gameMode]);

  useEffect(() => {
    if (
      gameMode === GameMode.DAILY &&
      gameState.number !== getCurrentDateNumber()
    ) {
      setGameState({
        win: undefined,
        rightCards: [{ ...initialPlayedCard, isFlipped: true }],
        wrongCards: [],
        nextCard: [initialNextCard],
        number: getCurrentDateNumber(),
        cardsToPlay: deckCopy,
      });
      setProgress(0);
    }
  }, [
    deckCopy,
    gameMode,
    gameState.number,
    initialNextCard,
    initialPlayedCard,
    setGameState,
  ]);

  const startOver = () => {
    deckCopy = boardFns.getGameModeDeck(gameMode, pointsToWin + lifes + 2);

    const [initialNextCard] = [deckCopy.shift()!];

    const [initialPlayedCard] = [deckCopy.shift()!];

    setCardToFlip(initialPlayedCard);
    setGameState({
      win: undefined,
      rightCards: [{ ...initialPlayedCard, isFlipped: true }],
      wrongCards: [],
      nextCard: [initialNextCard],
      number: 0,
      cardsToPlay: deckCopy,
    });
    setProgress(0);
  };

  useLayoutEffect(() => {
    if (gameState.wrongCards.length === lifes) {
      setGameState((prevState: GameState): GameState => {
        return { ...prevState, win: false };
      });
    }
  }, [gameState.wrongCards, setGameState, lifes]);

  const onDragStart = () => {
    setIsDragging(false);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    setIsDragging(true);
    if (
      !destination ||
      destination.droppableId === 'next-card' ||
      !gameState.nextCard
    ) {
      return;
    }
    if (
      gameState.wrongCards.length + gameState.rightCards.length === 1 &&
      isDaily
    ) {
      axios.put(`https://chronoline-backend.onrender.com/visits/${gameState.number}`);
    }
    if (
      destination.droppableId === 'timeline-cards' &&
      source.droppableId === 'next-card'
    ) {
      let sourceClone: Card[];
      let destClone: Card[];

      sourceClone = Array.from(gameState.nextCard);
      destClone = Array.from(gameState.rightCards);
      const nextCardClone = [...gameState.nextCard];

      const [removed] = sourceClone.splice(source.index, 1);
      destClone.splice(destination.index, 0, removed);
      setGameState((prevGameState: GameState): GameState => {
        return { ...prevGameState, nextCard: undefined };
      });
      if (checkCorrect(destination.index, destClone, nextCardClone)) {
        setGameState((prevGameState: GameState) => {
          return { ...prevGameState, rightCards: destClone };
        });
        setTimeout(
          () => document.getElementById(removed.id)?.classList.add('flipped'),
          125
        );
        setTimeout(() => {
          setGameState((prevGameState: GameState) => {
            const prevCards = prevGameState.rightCards;
            const updatedCards = prevCards.map((card) => {
              if (card.id === removed.id) {
                return { ...card, isFlipped: true };
              }
              return card;
            });
            return { ...prevGameState, rightCards: updatedCards };
          });
        }, 500);
        setTimeout(() => setProgress(gameState.rightCards.length), 500);
        if (gameState.rightCards.length !== pointsToWin) {
          setGameState((prevGameState: GameState) => {
            const [nextCard, cardsToPlay] = boardFns.getNextCard(
              gameState.cardsToPlay,
              nextCardClone
            );
            return {
              ...prevGameState,
              nextCard: nextCard,
              cardsToPlay: cardsToPlay,
            };
          });
        } else {
          setGameState((prevGameState: GameState) => {
            return { ...prevGameState, win: true };
          });
        }
      } else {
        setGameState((prevGameState: GameState) => {
          return { ...prevGameState, rightCards: destClone };
        });
        setTimeout(
          () => document.getElementById(removed.id)?.classList.add('flipped'),
          125
        );
        setTimeout(() => {
          document.getElementById(removed.id)?.classList.add('wrong-effect');
        }, 1000);
        setTimeout(() => {
          let rightCardsClone = destClone;
          rightCardsClone.splice(destination.index, 1);
          const [nextCard, cardsToPlay] = boardFns.getNextCard(
            gameState.cardsToPlay,
            nextCardClone
          );
          setGameState((prevGameState: GameState) => {
            return {
              ...prevGameState,
              nextCard: nextCard,
              cardsToPlay: cardsToPlay,
              wrongCards: [...gameState.wrongCards, removed],
              rightCards: [...rightCardsClone],
            };
          });
        }, 2000);
      }
    }
  };
  return (
    <>
      <div className="board-ctn">
        <section className="board-header-ctn">
          <ChronolinePopup isOpen={isOpen} setIsOpen={setIsOpen}>
            <Instructions />
          </ChronolinePopup>
          <div className="scorepanel-ctn">
            <ScorePanel
              progress={progress}
              gameState={gameState}
              lifes={lifes}
              pointsToWin={pointsToWin}
            />
          </div>
        </section>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <main className="wrapper">
            <div className="top">
              <PlayedCards
                isDragDisabled={isDragging}
                cards={gameState.rightCards}
                cardToFlip={cardToFlip?.id}
              />
            </div>
            <div className="bottom">
              <EndGameView
                gameState={gameState}
                isDaily={isDaily}
                startOver={startOver}
                gameMode={gameMode}
              />
              {gameState.win === undefined &&
                gameState.cardsToPlay.length > -1 &&
                gameState.nextCard && (
                  <NextCard nextCard={gameState.nextCard} />
                )}
            </div>
          </main>
        </DragDropContext>
        <footer>
          <nav className="board-navbar">
            <button className="icon" onClick={() => navigate('/')}>
              <FontAwesomeIcon icon={faHome} />
            </button>
            {isDaily && (
              <HeaderIcon icon={faBarChart}>
                <Stats />
              </HeaderIcon>
            )}
            {!isDaily && <StartOver startOver={startOver} />}
            <HeaderIcon icon={faCircleQuestion}>
              <Instructions />
            </HeaderIcon>
          </nav>
        </footer>
      </div>
    </>
  );
}

export default Board;
