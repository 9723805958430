import { useEffect, useState } from 'react';
import { useLocalStorage } from './localStorage';

export function useFirstLogin(): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] {
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useLocalStorage<boolean>(
    'chronoline_isFirstTimeLogin',
    true
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isFirstTimeLogin === true) {
      setTimeout(() => {
        setIsOpen(true);
        setIsFirstTimeLogin(false);
      }, 500);
    }
  }, [isFirstTimeLogin, setIsOpen, setIsFirstTimeLogin]);

  return [isOpen, setIsOpen];
}
