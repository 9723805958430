import { Card } from './types';

const url = '/images/invention-img/';

export const inventionCards: Card[] = [
  {
    id: 'i-1',
    date: -3500,
    content: 'Invention of the wheel',
    img: url + 'wheel.webp',
  },
  {
    id: 'i-2',
    date: -3000,
    content: 'Invention of papyrus',
    img: url + 'papyrus.webp',
  },
  {
    id: 'i-3',
    date: -2650,
    content: 'Invention of the ruler',
    img: url + 'ruler.webp',
  },
  {
    id: 'i-4',
    date: -2600,
    content: 'Invention of the balance, weights and scales',
    img: url + 'balance.webp',
  },
  {
    id: 'i-5',
    date: -2300,
    content: 'Invention of the dictionary',
    img: url + 'dictionary.webp',
  },
  {
    id: 'i-6',
    date: -100,
    content: 'Invention of the modern scissors',
    img: url + 'scissors.webp',
  },
  {
    id: 'i-7',
    date: -1500,
    content: 'Invention of the sundial',
    img: url + 'sundial.webp',
  },
  {
    id: 'i-8',
    date: -1600,
    content: 'Invention of rubber',
    img: url + 'rubber.webp',
  },
  {
    id: 'i-9',
    date: -650,
    content: 'Invention of the crossbow',
    img: url + 'crossbow.webp',
  },
  {
    id: 'i-10',
    date: 570,
    content: 'Invention of chess',
    img: url + 'chess.webp',
  },
  {
    id: 'i-11',
    date: 1286,
    content: 'Invention of the eyeglasses',
    img: url + 'eyeglasses.webp',
  },
  {
    id: 'i-12',
    date: 1608,
    content: 'Invention of the telescope',
    img: url + 'telescope.webp',
  },
  {
    id: 'i-13',
    date: 1700,
    content: 'Invention of the piano',
    img: url + 'piano.webp',
  },
  {
    id: 'i-14',
    date: 1798,
    content: 'Invention of the smallpox vaccine',
    img: url + 'vaccine.webp',
  },
  {
    id: 'i-15',
    date: 1837,
    content: 'Invention of the Morse code',
    img: url + 'morse.webp',
  },
  {
    id: 'i-16',
    date: 1653,
    content: 'Invention of the mailbox',
    img: url + 'mailbox.webp',
  },
  {
    id: 'i-17',
    date: 900,
    content: 'Invention of card games',
    img: url + 'card-games.webp',
  },
  {
    id: 'i-18',
    date: 1938,
    content: 'Invention of the instant coffee',
    img: url + 'instant-coffee.webp',
  },
  {
    id: 'i-19',
    date: 1929,
    content: 'Invention of the sunglasses',
    img: url + 'sunglasses.webp',
  },
  {
    id: 'i-20',
    date: -3000,
    content: 'Invention of the water clock',
    img: url + 'water-clock.webp',
  },
  {
    id: 'i-21',
    date: 1735,
    content: 'Invention of the chronometer',
    img: url + 'chronometer.webp',
  },
  {
    id: 'i-22',
    date: 1770,
    content: 'Invention of the razor',
    img: url + 'razor.webp',
  },
  {
    id: 'i-23',
    date: 1610,
    content: 'Invention of the kitchen knife',
    img: url + 'knife.webp',
  },
  {
    id: 'i-24',
    date: -1500,
    content: 'Invention of soap',
    img: url + 'soap.webp',
  },
  {
    id: 'i-25',
    date: 1884,
    content: 'Invention of the trash can',
    img: url + 'bin.webp',
  },
  {
    id: 'i-26',
    date: -6000,
    content: 'Invention of wine',
    img: url + 'wine.webp',
  },
  {
    id: 'i-27',
    date: -680,
    content: 'Invention of the coin',
    img: url + 'coin.webp',
  },
  {
    id: 'i-28',
    date: -35000,
    content: 'Invention of the flute',
    img: url + 'flute.webp',
  },
  {
    id: 'i-29',
    date: 1973,
    content: 'Invention of the mobile phone',
    img: url + 'mobile-phone.webp',
  },
  {
    id: 'i-30',
    date: 1781,
    content: 'Invention of the french fries',
    img: url + 'french-fries.webp',
  },
  {
    id: 'i-31',
    date: 1908,
    content: 'Invention of the tea bag',
    img: url + 'tea-bag.webp',
  },
  {
    id: 'i-32',
    date: 1830,
    content: 'Invention of the baguette',
    img: url + 'baguette.webp',
  },
  {
    id: 'i-33',
    date: 1863,
    content: 'Invention of the croissant',
    img: url + 'croissant.webp',
  },
  {
    id: 'i-34',
    date: -1250,
    content: 'Invention of the die',
    img: url + 'dice.webp',
  },
  {
    id: 'i-35',
    date: 1664,
    content: 'Invention of champagne',
    img: url + 'champagne.webp',
  },
  {
    id: 'i-36',
    date: -200,
    content: 'Invention of the glass bottle',
    img: url + 'glass-bottle.webp',
  },
  {
    id: 'i-37',
    date: -2000,
    content: 'Invention of the key',
    img: url + 'key.webp',
  },
  {
    id: 'i-38',
    date: 1769,
    content: 'Invention of the automobile',
    img: url + 'automobile.webp',
  },
  {
    id: 'i-39',
    date: 1971,
    content: 'Invention of the game console',
    img: url + 'game-console.webp',
  },
  {
    id: 'i-40',
    date: 1868,
    content: 'Invention of the traffic light',
    img: url + 'traffic-light.webp',
  },
  {
    id: 'i-41',
    date: 972,
    content: 'Invention of the fork',
    img: url + 'fork.webp',
  },
  {
    id: 'i-42',
    date: 1863,
    content: 'Invention of soccer',
    img: url + 'football.webp',
  },
  {
    id: 'i-43',
    date: 1608,
    content: 'Invention of the binoculars',
    img: url + 'binoculars.webp',
  },
  {
    id: 'i-44',
    date: 1810,
    content: 'Invention of the tin can',
    img: url + 'tin-can.webp',
  },
  {
    id: 'i-45',
    date: 1836,
    content: 'Invention of the revolver',
    img: url + 'revolver.webp',
  },
  {
    id: 'i-46',
    date: -1200,
    content: 'Invention of Chinese calligraphy',
    img: url + 'chinese-calligraphy.webp',
  },
  {
    id: 'i-47',
    date: 1879,
    content: 'Invention of the light bulb',
    img: url + 'light-bulb.webp',
  },
  {
    id: 'i-48',
    date: 1436,
    content: 'Invention of the printing press',
    img: url + 'printing-press.webp',
  },
  {
    id: 'i-49',
    date: -200,
    content: 'Invention of the compass',
    img: url + 'compass.webp',
  },
  {
    id: 'i-50',
    date: 1835,
    content: 'Invention of the electrical telegraph',
    img: url + 'telegraph.webp',
  },
  {
    id: 'i-51',
    date: 1897,
    content: 'Invention of the radio',
    img: url + 'radio.webp',
  },
  {
    id: 'i-52',
    date: 1927,
    content: 'Invention of the television',
    img: url + 'television.webp',
  },
  {
    id: 'i-53',
    date: 1903,
    content: 'Invention of the airplane',
    img: url + 'airplane.webp',
  },
  {
    id: 'i-54',
    date: 1600,
    content: 'Invention of the microscope',
    img: url + 'microscope.webp',
  },
  {
    id: 'i-55',
    date: 1960,
    content: 'Invention of the laser',
    img: url + 'laser.webp',
  },
  {
    id: 'i-56',
    date: 1800,
    content: 'Invention of the battery',
    img: url + 'battery.webp',
  },
  {
    id: 'i-57',
    date: 1795,
    content: 'Invention of the modern pencil',
    img: url + 'pencil.webp',
  },
  {
    id: 'i-58',
    date: 1826,
    content: 'Invention of the friction match',
    img: url + 'match.webp',
  },
  {
    id: 'i-59',
    date: 1712,
    content: 'Invention of the steam engine',
    img: url + 'steam-engine.webp',
  },
  {
    id: 'i-60',
    date: 1937,
    content: 'Invention of the mechanical computer',
    img: url + 'mechanical-computer.webp',
  },
];
