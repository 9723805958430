import { Card } from './types';

const url = '/images/people-img/';

export const peopleCards: Card[] = [
  {
    id: 'p-1',
    date: 1452,
    content: "Leonardo da Vinci's birthdate",
    img: url + 'leonardo_da_vinci.webp',
  },

  {
    id: 'p-2',
    date: 1775,
    content: "Jane Austen's birthdate",
    img: url + 'jane_austen.jpg',
  },

  {
    id: 'p-3',
    date: 1809,
    content: "Abraham Lincoln's birthdate",
    img: url + 'abraham_lincon.webp',
  },

  {
    id: 'p-4',
    date: 1820,
    content: "Florence Nightingale's birthdate",
    img: url + 'florence_nightingale.jpg',
  },

  {
    id: 'p-5',
    date: 1809,
    content: "Charles Darwin's birthdate",
    img: url + 'charles_darwin.jpg',
  },

  {
    id: 'p-6',
    date: 1830,
    content: "Emily Dickinson's birthdate",
    img: url + 'emily_dickinson.webp',
  },

  {
    id: 'p-7',
    date: 1840,
    content: "Auguste Rodin's birthdate",
    img: url + 'auguste_rodin.jpg',
  },

  {
    id: 'p-8',
    date: 1821,
    content: "Elizabeth Blackwell's birthdate",
    img: url + 'elizabeth_blackwell.jpg',
  },

  {
    id: 'p-9',
    date: 1862,
    content: "Gustav Klimt's birthdate",
    img: url + 'gustav_klimt.jpg',
  },

  {
    id: 'p-10',
    date: 1867,
    content: "Marie Curie's birthdate",
    img: url + 'marie_curie.jpg',
  },

  {
    id: 'p-11',
    date: 1879,
    content: "Albert Einstein's birthdate",
    img: url + 'albert_einstein.jpg',
  },

  {
    id: 'p-12',
    date: 1874,
    content: "Winston Churchill's birthdate",
    img: url + 'winston_churchill.webp',
  },

  {
    id: 'p-13',
    date: 1884,
    content: "Eleanor Roosevelt's birthdate",
    img: url + 'eleanor_roosevelt.jpg',
  },

  {
    id: 'p-14',
    date: 1867,
    content: "Frank Lloyd Wright's birthdate",
    img: url + 'frank_lloyd_wright.jpg',
  },

  {
    id: 'p-15',
    date: 1869,
    content: "Mahatma Gandhi's birthdate",
    img: url + 'mahatma_ghandi.jpg',
  },

  {
    id: 'p-16',
    date: 1881,
    content: "Pablo Picasso's birthdate",
    img: url + 'pablo_picasso.jpg',
  },

  {
    id: 'p-17',
    date: 1897,
    content: "Amelia Earhart's birthdate",
    img: url + 'amelia_earhart.jpg',
  },

  {
    id: 'p-18',
    date: 1856,
    content: "Sigmund Freud's birthdate",
    img: url + 'sigmund_freud.webp',
  },

  {
    id: 'p-19',
    date: -4,
    content: "Jesus Christ's birthdate",
    img: url + 'jesus_christ.webp',
  },

  {
    id: 'p-20',
    date: 1769,
    content: "Napoleon Bonaparte's birthdate",
    img: url + 'napoleon_bonaparte.jpg',
  },

  {
    id: 'p-21',
    date: -1507,
    content: "Hatshepsut's birthdate",
    img: url + 'hatshepsut.jpg',
  },

  {
    id: 'p-22',
    date: -1401,
    content: 'Amenhotep III birthdate',
    img: url + 'amenhotep_III.jpg',
  },

  {
    id: 'p-23',
    date: -356,
    content: "Alexander the Great's birthdate",
    img: url + 'alexander_the_great.webp',
  },

  {
    id: 'p-24',
    date: -304,
    content: "Ashoka the Great's birthdate",
    img: url + 'ashoka_the_great.jpg',
  },

  {
    id: 'p-25',
    date: -100,
    content: "Julius Caesar's birthdate",
    img: url + 'julius_caesar.webp',
  },

  {
    id: 'p-26',
    date: -63,
    content: "Caesar Augustus' birthdate",
    img: url + 'caesar_augustus.webp',
  },

  {
    id: 'p-27',
    date: -69,
    content: "Cleopatra's birthdate",
    img: url + 'cleopatra.jpg',
  },

  {
    id: 'p-28',
    date: 30,
    content: "Boudica's birthdate",
    img: url + 'boudica.jpg',
  },

  {
    id: 'p-29',
    date: 1996,
    content: "Lorde's birthdate",
    img: url + 'lorde.jpg',
    attr: 'By ThorntonDrury - Own work, CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=70360203',
  },

  {
    id: 'p-30',
    date: 1904,
    content: "Deng Xiaoping's, architect of modern China, birthdate",
    img: url + 'deng-xiapoing.webp',
  },

  {
    id: 'p-31',
    date: 1102,
    content: "Empress Matilda's birthdate",
    img: url + 'empress_matilda.webp',
  },

  {
    id: 'p-32',
    date: 1122,
    content: "Eleanor of Aquitaine's birthdate",
    img: url + 'eleanor_of_aquitaine.jpg',
  },

  {
    id: 'p-33',
    date: 1133,
    content: "Henry II's birthdate",
    img: url + 'henry_II.jpg',
  },

  {
    id: 'p-34',
    date: 1162,
    content: "Genghis Khan's birthdate",
    img: url + 'genghis_khan.jpg',
  },

  {
    id: 'p-35',
    date: 1239,
    content: "Edward I's birthdate",
    img: url + 'edward_I.jpg',
  },

  {
    id: 'p-36',
    date: 1280,
    content: "Mansa Musa's birthdate",
    img: url + 'mansa_musa.jpg',
  },

  {
    id: 'p-37',
    date: 1295,
    content: "Isabella of France's birthdate",
    img: url + 'isabella_of_france.webp',
  },

  {
    id: 'p-38',
    date: 1412,
    content: "Joan of Arc's birthdate",
    img: url + 'joan_of_arc.jpg',
  },

  {
    id: 'p-39',
    date: 1451,
    content: "Christopher Columbus' birthdate",
    img: url + 'christopher_columbus.jpg',
  },

  {
    id: 'p-40',
    date: 1451,
    content: "Isabella I of Castile's birthdate",
    img: url + 'isabella_of_castile.jpg',
  },

  {
    id: 'p-41',
    date: 1533,
    content: "Elizabeth I's birthdate",
    img: url + 'elizabeth_I.webp',
  },

  {
    id: 'p-42',
    date: 1540,
    content: "Francis Drake's birthdate",
    img: url + 'francis_drake.jpg',
  },

  {
    id: 'p-43',
    date: 1542,
    content: "Mary, Queen of Scots' birthdate",
    img: url + 'mary_queen_of_scots.jpg',
  },

  {
    id: 'p-44',
    date: 1564,
    content: "William Shakespeare's birthdate",
    img: url + 'william_shakespeare.jpg',
  },

  {
    id: 'p-45',
    date: 1638,
    content: "Louis XIV's birthdate",
    img: url + 'louis_XIV.webp',
  },

  {
    id: 'p-46',
    date: 1643,
    content: "Isaac Newton's birthdate",
    img: url + 'isaac_newton.jpg',
  },

  {
    id: 'p-47',
    date: 1729,
    content: "Catherine the Great's birthdate",
    img: url + 'catherine_the_great.jpg',
  },

  {
    id: 'p-48',
    date: 1732,
    content: "George Washington's birthdate",
    img: url + 'george_washington.webp',
  },

  {
    id: 'p-49',
    date: 1743,
    content: "Thomas Jefferson's birthdate",
    img: url + 'thomas_jefferson.jpg',
  },

  {
    id: 'p-50',
    date: 1755,
    content: "Marie Antoinette's birthdate",
    img: url + 'marie-antoinette.jpg',
  },

  {
    id: 'p-51',
    date: 1755,
    content: "Alexander Hamilton's birthdate",
    img: url + 'alexander_hamilton.jpg',
  },

  {
    id: 'p-52',
    date: 2001,
    content: "Billie Eilish's birthdate",
    img: url + 'billie_eilish.jpg',
    attr: 'By Toglenn - Own work, CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=86396007',
  },

  {
    id: 'p-53',
    date: 1812,
    content: "Charles Dickens' birthdate",
    img: url + 'charles_dickens.jpg',
  },

  {
    id: 'p-54',
    date: 1818,
    content: "Karl Marx's birthdate",
    img: url + 'karl_marx.jpg',
  },

  {
    id: 'p-55',
    date: 1819,
    content: "Queen Victoria's birthdate",
    img: url + 'queen_victoria.jpg',
  },

  {
    id: 'p-56',
    date: 1986,
    content: "Lena Dunham's birthdate",
    img: url + 'lena_dunham.jpg',
    attr: 'By David Shankbone - Own work, CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=19231884',
  },

  {
    id: 'p-57',
    date: 1878,
    content: "Josef Stalin's birthdate",
    img: url + 'josef_stalin.jpg',
  },

  {
    id: 'p-58',
    date: 1989,
    content: "Joe Jonas's birthdate",
    img: url + 'joe_jonas.jpg',
    attr: 'By Anthony Citranohttp://www.zigzaglens.com - Joe Jonas, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=18037682',
  },

  {
    id: 'p-59',
    date: 1913,
    content: "Rosa Parks' birthdate",
    img: url + 'rosa_parks.jpg',
  },

  {
    id: 'p-60',
    date: 1918,
    content: "Nelson Mandela's birthdate",
    img: url + 'nelson_mandela.jpg',
    attr: 'By © copyright John Mathew Smith 2001, CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=74768082',
  },

  {
    id: 'p-61',
    date: 1929,
    content: "Martin Luther King's birthdate",
    img: url + 'martin_luther_king.webp',
  },

  {
    id: 'p-62',
    date: 1929,
    content: "Anne Frank's birthdate",
    img: url + 'anne_frank.jpg',
  },

  {
    id: 'p-63',
    date: 1942,
    content: "Stephen Hawking's birthdate",
    img: url + 'stephen_hawking.webp',
  },

  {
    id: 'p-64',
    date: 1883,
    content: "Coco Chanel's birthdate",
    img: url + 'coco_chanel.jpg',
    attr: 'By Los Angeles Times - https://digital.library.ucla.edu/catalog/ark:/21198/zz0002nwv5, CC BY 4.0, https://commons.wikimedia.org/w/index.php?curid=124657035',
  },

  {
    id: 'p-65',
    date: 1926,
    content: "Elizabeth II's birthdate",
    img: url + 'queen_elizabeth_II.jpg',
    attr: 'By Unknown photographer for Government of Canada - Flickr: Queen Elizabeth II wearing crown, blue sash and pink gownThis is a retouched picture, which means that it has been digitally altered from its original version. Modifications made by nagualdesign., CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=122899796',
  },

  {
    id: 'p-66',
    date: 1958,
    content: "Michael Jackson's birthdate",
    img: url + 'michael_jackson.jpg',
    attr: 'By Zoran Veselinovic - Flickr, CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=15326180',
  },

  {
    id: 'p-67',
    date: 1926,
    content: "Marilyn Monroe's birthdate",
    img: url + 'marilyn_monroe.jpg',
  },

  {
    id: 'p-68',
    date: 1961,
    content: "Barack Obama's birthdate",
    img: url + 'barack_obama.webp',
  },

  {
    id: 'p-69',
    date: 1756,
    content: "Wolfgang Amadeus Mozart's birthdate",
    img: url + 'mozart.jpg',
  },

  {
    id: 'p-70',
    date: 1961,
    content: "Diana Spencer's birthdate",
    img: url + 'diana.jpg',
    attr: 'By John Mathew Smith &amp; www.celebrity-photos.com from Laurel Maryland, USA (Archived link) - BEST ALL-TIME DIANA! (Archived link), CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=85061623',
  },

  {
    id: 'p-71',
    date: 1965,
    content: "J.K.Rowling's birthdate",
    img: url + 'jk_rowling.jpg',
    attr: 'By Daniel Ogren - Flickr: 100405_EasterEggRoll_683, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=15164977',
  },

  {
    id: 'p-72',
    date: 2003,
    content: "Greta Thunberg's birthdate",
    img: url + 'greta_thunberg.jpg',
    attr: 'By Raph_PH - Glastonbury2022 (218 of 413), CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=119919011',
  },

  {
    id: 'p-73',
    date: 1958,
    content: "Madonna's birthdate",
    img: url + 'madonna.jpg',
    attr: 'By chrisweger - Madonna Rebel Heart Tour 2015 - Stockholm, CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=52196466',
  },

  {
    id: 'p-74',
    date: 1987,
    content: "Lionel Messi's birthdate",
    img: url + 'messi.jpg',
    attr: 'By Tasnim News Agency, CC BY 4.0, https://commons.wikimedia.org/w/index.php?curid=126494101',
  },

  {
    id: 'p-75',
    date: 1981,
    content: "Roger Federer's birthdate",
    img: url + 'federer.jpg',
    attr: 'By si.robi - [1], CC BY-SA 2.0, https://commons.wikimedia.org/w/index.php?curid=50252380',
  },

  {
    id: 'p-76',
    date: 1986,
    content: "Usain Bolt's birthdate",
    img: url + 'usain_bolt.jpg',
    attr: 'By Fernando Frazão/Agência Brasil - http://agenciabrasil.ebc.com.br/rio-2016/foto/2016-08/bolt-ganha-medalha-de-ouro-no-4-x-100-metros, CC BY 3.0 br, https://commons.wikimedia.org/w/index.php?curid=50787143',
  },

  {
    id: 'p-77',
    date: 1925,
    content: "Margaret Thatcher's birthdate",
    img: url + 'margaret_thatcher.jpg',
    attr: 'By derivative work by Begoon - This file was derived from: Margaret Thatcher stock portrait.tif, Copyrighted free use, https://commons.wikimedia.org/w/index.php?curid=84448824',
  },

  {
    id: 'p-78',
    date: 1985,
    content: "Cristiano Ronaldo's birthdate",
    img: url + 'cristiano_ronaldo.webp',
    attr: 'By Анна Нэсси - soccer.ru, CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=69972366',
  },

  {
    id: 'p-79',
    date: 1977,
    content: "Shakira's birthdate",
    img: url + 'shakira.webp',
    attr: 'By Condé Nast (through Vogue Taiwan), CC BY 3.0, https://commons.wikimedia.org/w/index.php?curid=107804294',
  },

  {
    id: 'p-80',
    date: 1963,
    content: "Brad Pitt's birthdate",
    img: url + 'brad_pitt.webp',
    attr: 'By © Glenn Francis, www.PacificProDigital.com, CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=81371623',
  },

  {
    id: 'p-81',
    date: 1982,
    content: "Mark Zuckerberg's birthdate",
    img: url + 'zuckerberg.jpg',
    attr: 'By [https://www.flickr.com/people/1971867@N05 - [https://www.flickr.com/photos/1971867@N05, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=90784061',
  },

  {
    id: 'p-82',
    date: 1984,
    content: "Ashton Kutcher's birthdate",
    img: url + 'ashton_kutcher.webp',
    attr: 'By David Shankbone - Flickr: Ashton Kutcher by David Shankbone 2010 NYC, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=29477439',
  },

  {
    id: 'p-83',
    date: 1987,
    content: "Miley Cyrus's birthdate",
    img: url + 'miley_cyrus.jpg',
    attr: 'By Raphael Pour-Hashemi - Primavera19_-226, CC BY 2.0, https://commons.wikimedia.org/w/index.php?curid=83556743',
  },

  {
    id: 'p-84',
    date: 1989,
    content: "Daniel Radcliffe's birthdate",
    img: url + 'daniel_radcliffe.jpg',
    attr: 'By Philip Romano - Own work, CC BY-SA 4.0, https://commons.wikimedia.org/w/index.php?curid=123984908',
  },

  {
    id: 'p-85',
    date: -428,
    content: "Plato's birthdate",
    img: url + 'plato.jpg',
  },
  {
    id: 'p-86',
    date: 1923,
    content: "Lee Kuan Yew's, father of Singapore, birthdate",
    img: url + 'lee-kuan-yew.webp',
  },
];
