import { Card } from './types';

const url = '/images/worldHistory-img/';

export const worldHistoryCards: Card[] = [
  {
    id: 'wh-1',
    date: 1215,
    content: 'The signing of the Magna Carta',
    img: url + 'magna_carta.jpg',
  },
  {
    id: 'wh-2',
    date: 1492,
    content: 'Christopher Columbus discovers America',
    img: url + 'discovery_america.jpg',
  },
  {
    id: 'wh-3',
    date: 1517,
    content: 'The Protestant Reformation',
    img: url + 'protestant_reformation.jpg',
  },
  {
    id: 'wh-4',
    date: 1776,
    content: 'Declaration of Independence of the USA',
    img: url + 'declaration_of_independence.jpg',
  },
  {
    id: 'wh-5',
    date: 1789,
    content: 'The French Revolution',
    img: url + 'french_revolution.jpg',
  },
  {
    id: 'wh-6',
    date: 1865,
    content: 'The abolition of slavery in the USA',
    img: url + 'abolition_slavery.jpg',
  },
  {
    id: 'wh-7',
    date: 1914,
    content: 'The assassination of Archduke Ferdinand',
    img: url + 'assesination_Archiduke.jpg',
  },
  {
    id: 'wh-8',
    date: 1919,
    content: 'The signing of the Treaty of Versailles',
    img: url + 'treaty_of_versailles.jpg',
  },
  {
    id: 'wh-9',
    date: 1945,
    content: 'The end of World War II',
    img: url + 'end_ww2.jpg',
  },
  {
    id: 'wh-10',
    date: 1991,
    content: 'The collapse of the Soviet Union',
    img: url + 'collapse_of_soviet_union.png',
  },
  {
    id: 'wh-11',
    date: 1519,
    content: 'Magellan proves the earth is round',
    img: url + 'round-earth.webp',
  },

  {
    id: 'wh-12',
    date: 1989,
    content: 'The fall of the Berlin Wall',
    img: url + 'berlin-wall.webp',
  },

  {
    id: 'wh-13',
    date: 1096,
    content: 'The First Crusade',
    img: url + 'First-crusade.jpg',
  },

  {
    id: 'wh-14',
    date: 1861,
    content: 'The American civil war begins',
    img: url + 'american-civil-war.webp',
  },

  {
    id: 'wh-15',
    date: 1986,
    content: 'Chernobyl nuclear disaster',
    img: url + 'chernobyl.webp',
  },

  {
    id: 'wh-16',
    date: 1202,
    content: 'The sack of Constantinople',
    img: url + 'fall-constantinople.jpg',
  },

  {
    id: 'wh-17',
    date: 2019,
    content: 'Start of the COVID-19 pandemic',
    img: url + 'covid.webp',
  },

  {
    id: 'wh-18',
    date: 1993,
    content: 'End of apartheid in South Africa',
    img: url + 'end-apartheid.webp',
  },

  {
    id: 'wh-19',
    date: 1291,
    content: 'The fall of Acre',
    img: url + 'fall-acre.jpg',
  },

  {
    id: 'wh-20',
    date: 1455,
    content: 'The Gutenberg Bible is printed in Germany',
    img: url + 'gutenberg-bible.webp',
  },

  {
    id: 'wh-21',
    date: 1297,
    content: 'The Battle of Stirling Bridge',
    img: url + 'battle-stirling.jpg',
  },

  {
    id: 'wh-22',
    date: 1302,
    content: 'The signing of the Treaty of Paris',
    img: url + 'Treaty_of_Paris.jpg',
  },

  {
    id: 'wh-23',
    date: 1305,
    content: 'The execution of William Wallace',
    img: url + 'The_Trial_of_William_Wallace.jpg',
  },

  {
    id: 'wh-24',
    date: 1296,
    content: 'First war of Scottish independence',
    img: url + 'first-war-scottish-independence.webp',
  },

  {
    id: 'wh-25',
    date: 1337,
    content: "The start of the Hundred Years' War",
    img: url + 'Hundred_years_war.jpg',
  },

  {
    id: 'wh-26',
    date: 1347,
    content: 'The outbreak of the Black Death in Europe',
    img: url + 'back_death.png',
  },

  {
    id: 'wh-27',
    date: 1648,
    content: 'Peace of Westphalia (end of 30 years war)',
    img: url + 'peace-of-westphalia.webp',
  },
  {
    id: 'wh-28',
    date: 1381,
    content: "The Peasants' Revolt in England",
    img: url + 'peasants_revolt.jpg',
  },
  {
    id: 'wh-29',
    date: 1271,
    content: 'Marco Polo begins his journey to China',
    img: url + 'marco-polo.jpg',
  },
  {
    id: 'wh-30',
    date: -20000,
    content: 'Earliest evidence of humans exerting control over grain',
    img: url + 'agriculture.jpg',
  },
  {
    id: 'wh-31',
    date: -4500,
    content: 'The first city emerges in Mesopotamia',
    img: url + 'first-city-mesopotamia.jpg',
  },
  {
    id: 'wh-32',
    date: -3500,
    content: 'The first wheeled vehicles appear',
    img: url + 'first-wheel.jpg',
  },
  {
    id: 'wh-33',
    date: -3200,
    content: 'The first writing systems appear',
    img: url + 'first-writing.jpg',
  },
  {
    id: 'wh-34',
    date: -2560,
    content: 'The Great Pyramid of Giza is built',
    img: url + 'pyramid-giza.jpg',
  },
  {
    id: 'wh-35',
    date: -2000,
    content: 'Beginning of Iron Age',
    img: url + 'iron-age.jpg',
  },
  {
    id: 'wh-36',
    date: -799,
    content: 'The Rise of Ancient Greek Civilization',
    img: url + 'greek-civilization.jpg',
  },
  {
    id: 'wh-37',
    date: -44,
    content: 'The birth of the modern calendar',
    img: url + 'modern-calendar.jpg',
  },
  {
    id: 'wh-38',
    date: 1088,
    content: 'The first university is established, at Bologna',
    img: url + 'university-of-bologna.webp',
  },
  {
    id: 'wh-39',
    date: 1150,
    content: 'King Suryavarman II builds Angkor Wat',
    img: url + 'AngkorWat.jpg',
  },
  {
    id: 'wh-40',
    date: 1600,
    content: 'William Shakespeare writes Hamlet',
    img: url + 'hamlet.jpg',
  },
  {
    id: 'wh-41',
    date: 1609,
    content: 'Galileo Galilei publishes The Starry Messenger',
    img: url + 'the-starry-messenger.jpg',
  },
  {
    id: 'wh-42',
    date: 1859,
    content: 'Charles Darwin publishes On the Origin of Species',
    img: url + 'darwin.jpg',
  },
  {
    id: 'wh-43',
    date: 1945,
    content: 'The United Nations is formed',
    img: url + 'united-nations.png',
  },
  {
    id: 'wh-44',
    date: 1969,
    content: 'First man lands on the Moon',
    img: url + 'moon-landing.jpg',
  },
  {
    id: 'wh-45',
    date: -195000,
    content: 'First evidence of Homo Sapiens',
    img: url + 'homo-sapiens.jpg',
  },
  {
    id: 'wh-46',
    date: -41000,
    content: 'First known musical instruments',
    img: url + 'first-instruments.jpg',
  },
  {
    id: 'wh-47',
    date: -33000,
    content: 'Earliest evidence of the domestication of the dog',
    img: url + 'domestication-dog.jpg',
  },
  {
    id: 'wh-48',
    date: -23000,
    content: 'Beginning of the most recent Ice Age',
    img: url + 'ice-age.jpg',
  },
  {
    id: 'wh-49',
    date: -7500,
    content: 'Domestication of cats',
    img: url + 'domestication-cat.jpg',
  },
  {
    id: 'wh-50',
    date: -4500,
    content: 'First evidence of bronze making',
    img: url + 'first-bronze.jpg',
  },
  {
    id: 'wh-51',
    date: -4000,
    content: 'Domestication of the horse',
    img: url + 'domestication-horse.jpg',
  },
  {
    id: 'wh-52',
    date: -3300,
    content: 'The Bronze Age begins',
    img: url + 'bronze-age.jpg',
  },
  {
    id: 'wh-53',
    date: -1400,
    content: 'The Ugarits invent the first alphabet',
    img: url + 'first-alphabet.jpg',
  },
  {
    id: 'wh-54',
    date: -776,
    content: 'The first Olympic Games are held at Olympia',
    img: url + 'first-olympic-games.jpg',
  },
  {
    id: 'wh-55',
    date: -753,
    content: 'Legendary date of founding of Rome',
    img: url + 'founding-of-rome.jpg',
  },
  {
    id: 'wh-56',
    date: -725,
    content: 'The Iliad, by Homer, is written down',
    img: url + 'Iliad.jpg',
  },
  {
    id: 'wh-57',
    date: -432,
    content: 'The Parthenon is built on the Acropolis in Athens',
    img: url + 'Parthenon.jpg',
  },
  {
    id: 'wh-58',
    date: -380,
    content: 'Plato writes The Republic',
    img: url + 'republic-plato.jpg',
  },
  {
    id: 'wh-59',
    date: -206,
    content: 'Completion of the first Great Wall of China',
    img: url + 'great-wall.jpg',
  },
  {
    id: 'wh-60',
    date: -200,
    content: 'Invention of paper',
    img: url + 'paper.jpg',
  },
  {
    id: 'wh-61',
    date: 79,
    content: 'The Mt. Vesuvius volcano erupts, burying Pompeii',
    img: url + 'destruction-pompeii.jpg',
  },
  {
    id: 'wh-62',
    date: 80,
    content: 'The Colosseum is built in Rome',
    img: url + 'Colosseum.jpg',
  },
  {
    id: 'wh-63',
    date: 122,
    content: "The Romans build Hadrian's Wall",
    img: url + 'hadrians-wall.jpg',
  },
  {
    id: 'wh-64',
    date: 160,
    content: 'The Antonine Plague begins in the Roman Empire',
    img: url + 'antonine-plague.jpg',
  },
  {
    id: 'wh-65',
    date: 1653,
    content: 'Construction of the Taj Mahal is completed',
    img: url + 'taj-mahal.webp',
    attr: 'By Taj_Mahal,_Agra,_India_edit2.jpg: Yann; edited by King of Heartsderivative work: Jbarta (talk) - Taj_Mahal,_Agra,_India_edit2.jpg, CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=17075129',
  },
  {
    id: 'wh-66',
    date: 1505,
    content: 'The Mona Lisa is painted by Leonardo',
    img: url + 'mona-lisa.webp',
  },
  {
    id: 'wh-67',
    date: 1605,
    content: 'First publication of Don Quixote',
    img: url + 'don-quijote.webp',
  },
];
