import { GameHistoric } from '../../utils/types';

export function countDaysPlayed(gameHistoric: GameHistoric) {
  const gameHistoricDates = Object.keys(gameHistoric);
  const filteredGameHistoric = gameHistoricDates.filter((date) => {
    return gameHistoric[date].win !== undefined;
  });

  return filteredGameHistoric.length;
}

export function calcWinsPercentage(gameHistoric: GameHistoric) {
  let countWins = 0;
  for (let key in gameHistoric) {
    if (gameHistoric[key].win === true) {
      countWins++;
    }
  }
  const winsPercentage = (countWins / countDaysPlayed(gameHistoric)) * 100;
  return countWins === 0 ? 0 : Math.round(winsPercentage);
}

export function calcAvgMistakes(gameHistoric: GameHistoric) {
  let totalMistakes = 0;
  for (let key in gameHistoric) {
    if (gameHistoric[key].win !== undefined) {
      totalMistakes += gameHistoric[key].wrongCardsIds.length;
    }
  }
  const avg = totalMistakes / countDaysPlayed(gameHistoric);

  return totalMistakes === 0 ? 0 : Math.round(avg * 10) / 10;
}

export function calcMistakeDistrib(gameHistoric: GameHistoric) {
  let mistakesObj: { [key: number]: number } = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };
  for (let key in gameHistoric) {
    if (gameHistoric[key].win !== undefined) {
      let nOfMistakes = gameHistoric[key].wrongCardsIds.length;
      mistakesObj[nOfMistakes]++;
    }
  }
  return mistakesObj;
}

export function normaliseMistakes(mistakesObj: { [key: number]: number }) {
  const mistakesArr = Object.values(mistakesObj);
  const maxCount = Math.max(...mistakesArr);
  for (let key in mistakesObj) {
    mistakesObj[key] = (mistakesObj[key] / maxCount) * 100;
  }
  return mistakesObj;
}

export function calcMaxStreak(streakCount: number) {
  let maxStreak = 0;
  if (streakCount > maxStreak) {
    maxStreak = streakCount;
  }
  return maxStreak;
}

export function calcStreak(gameHistoric: GameHistoric) {
  let streakCount = 0;
  for (let key in gameHistoric) {
    if (gameHistoric[key].win === true) {
      streakCount++;
    } else if (gameHistoric[key].win === false) {
      streakCount = 0;
    }
  }
  return streakCount;
}
