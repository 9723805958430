import './ProgressBar.scss';

interface ProgressBarProps {
  progress: number;
  pointsToWin: number;
}

export function ProgressBar({ progress, pointsToWin }: ProgressBarProps) {
  return (
    <>
      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${(progress / pointsToWin) * 100}%` }}
        ></div>
        <span className="progress-text">
          {progress}/{pointsToWin}
        </span>
      </div>
    </>
  );
}
