import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Countdown.scss';

export function Countdown(): JSX.Element {
  const { t } = useTranslation();
  function getCountdown() {
    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
    const now = new Date();
    const target = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0,
      0,
      0
    );
    const diff = target.getTime() - now.getTime();
    const secondsToNextDate = Math.floor(
      (diff / milliSecondsInASecond) % secondsInAMinute
    );
    const minutesToNextDate = Math.floor(
      (diff / (milliSecondsInASecond * minutesInAnHour)) % secondsInAMinute
    );
    const hoursToNextDate = Math.floor(
      (diff / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
    );
    return {
      hours: hoursToNextDate,
      minutes: minutesToNextDate,
      seconds: secondsToNextDate,
    };
  }
  const [countdown, setCountdown] = useState(getCountdown());

  useEffect(() => {
    const interval = setInterval(() => {
      const nextCountdown = getCountdown();
      if (
        nextCountdown.hours <= 0 &&
        nextCountdown.minutes <= 0 &&
        nextCountdown.seconds <= 0
      ) {
        window.location.reload();
      }
      setCountdown(nextCountdown);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="countdown-ctn">
      <h2>{t('countdown')}</h2>
      <h2>
        {countdown.hours}h : {countdown.minutes}min : {countdown.seconds}s
      </h2>
    </div>
  );
}
