import { GameState } from '../../utils/types';
import './ScorePanel.scss';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';

interface ScorePanelProps {
  gameState: GameState;
  lifes: number;
  progress: number;
  pointsToWin: number;
}
/*
interface SquareProps {
  wrongCard: Card;
}

function Square({ wrongCard }: SquareProps) {
  return (
    <div className="square-ctn">
      <div className="card">
        <div id={wrongCard.id} className="card-inner flipped">
          <div className="card-back">
            <img
              src={process.env.PUBLIC_URL + wrongCard.img}
              alt={wrongCard.content}
            ></img>
            <p className="card-date wrong">{`${wrongCard.date}`}</p>
            <p className="card-text">{wrongCard.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}*/

function ScorePanel({
  gameState,
  lifes,
  progress,
  pointsToWin,
}: ScorePanelProps) {
  const { t } = useTranslation();
  const generateHearts = () => {
    let hearts: JSX.Element[] = [];
    for (let i = 0; i < lifes - gameState.wrongCards.length; i++) {
      hearts.push(
        <span key={i} className="heart">
        </span>
        /*
        <div key={i} className="square">
          {gameState.wrongCards[i] ? (
            <Square wrongCard={gameState.wrongCards[i]} />
          ) : i === lifes - 1 ? (
            <span style={{ color: 'red', fontWeight: 600 }}>
              Game
              <br />
              Over
            </span>
          ) : (
            ''
          )}
        </div>*/
      );
    }
    return hearts;
  };

  return (
    <>
      <div className="panel-ctn">
        <div className="panel-item-ctn">
          <p className="panel-text">{t('scorePanel.lifes')}</p>
          <div className="hearts">{generateHearts()}</div>
          <p className="panel-text">{t('scorePanel.points')}</p>
          <ProgressBar progress={progress} pointsToWin={pointsToWin} />
        </div>
      </div>
    </>
  );
}

export default ScorePanel;
