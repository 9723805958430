import { getCurrentDateNumber } from '../../utils/getCurrentDate';
import { Card, GameMode } from '../../utils/types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function handleShare(
  wrongCards: Card[],
  rightCards: Card[],
  gameMode: GameMode,
  copyText: string,
  noCopyText: string
) {
  let textToCopy = '';
  if (gameMode === GameMode.DAILY) {
    textToCopy =
      '⏳ #Chronoline #' +
      getCurrentDateNumber() +
      '\n' +
      `${`💔 ${wrongCards.length}/5\n`}` +
      `${`🃏 ${rightCards.length - 1}/10\n`}` +
      'https://chronoline.app/daily';
  }
  if (gameMode === GameMode.FAMOUS_CHARACTERS) {
    textToCopy =
      '⏳ #Chronoline 👸🏻' +
      '\n' +
      `${`💔 ${wrongCards.length}/5\n`}` +
      `${`🃏 ${rightCards.length - 1}/10\n`}` +
      'https://chronoline.app/famous-characters/';
  }
  if (gameMode === GameMode.WORLD_HISTORY) {
    textToCopy =
      '⏳ #Chronoline 🏛️' +
      '\n' +
      `${`💔 ${wrongCards.length}/5\n`}` +
      `${`🃏 ${rightCards.length - 1}/10\n`}` +
      'https://chronoline.app/world-history/';
  }
  if (gameMode === GameMode.INVENTION) {
    textToCopy =
      '⏳ #Chronoline 💡' +
      '\n' +
      `${`💔 ${wrongCards.length}/5\n`}` +
      `${`🃏 ${rightCards.length - 1}/10\n`}` +
      'https://chronoline.app/inventions/';
  }
  try {
    await navigator.clipboard.writeText(textToCopy);
    toast.info(copyText, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  } catch {
    alert(noCopyText);
  }
}
